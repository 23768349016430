import { useRef } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import structure from '@/structure'
import { breakpoints, colors, fontSizes, fontWeights } from '@/styles'
import useMediaQuery from '@/hooks/use-media-query'

import MainImage from './main-image'

const CitySelector = dynamic(() => import('@/features/CitySelector'))
const PopularCities = dynamic(() => import('@/features/Home/PopularCities'), {
  ssr: false
})

const Clip = styled.section`
  min-height: 575px;
  max-height: calc(100vh + 180px);
  position: relative;
  background-color: ${colors.white};

  @media (max-width: 1440px) {
    background-color: #fcfcfc;
  }

  @media (max-width: ${breakpoints.tabletLandscape}) {
    background-color: ${colors.white};
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    min-height: 732px;
  }
`

const padding = {
  // Ratio of space between viewport width
  // and the image height that our content
  // can not be on top of.
  big: 0.275,
  medium: 0.32,
  small: 0.43891786179
}

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 80px calc(100vw * ${padding.big});

  @media (max-width: 1440px) {
    padding-bottom: calc(100vw * ${padding.medium});
  }

  @media (max-width: ${breakpoints.tabletLandscape}) {
    padding-bottom: calc(100vw * ${padding.small});
  }

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 0 40px 250px;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    padding: 0 16px 190px;
  }
`

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 48px;
  width: 100%;
  max-width: 690px;
  min-height: 110px;
  padding: 20px 28px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
  border-radius: 12px;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 38px;
    padding: 16px 12px;
    max-width: 540px;
    min-height: 92px;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    margin: 32px -16px 0;
  }
`

const PopularCitiesWrapper = styled.div`
  width: 100%;
  min-height: 16px;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    min-height: 38px;
  }
`

const HeroHeader = styled.h1`
  text-align: center;
  margin: ${structure.search.height + 60}px 0 0;
  color: ${colors.grey08};
  font-family: futura-pt-bold, Futura, sans-serif;
  font-size: 72px;
  font-weight: 700;
  line-height: 72px;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: 56px;
    line-height: 56px;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    margin: ${structure.search.height + 30}px 0 0;
    text-align: left;
    font-size: 42px;
    line-height: 44px;
    max-width: 385px;
  }

  ${props =>
    props.isChinese &&
    `
    font-size: 60px;
    line-height: 60px;

    @media (max-width: ${breakpoints.tabletLandscape}) {
      font-size: 50px;
      line-height: 50px;
    }
    @media (max-width: ${breakpoints.ipadMiniMax}) {
      font-size: 42px;
      line-height: 44px;
    }
  `}
`

const HeroTagline = styled.div`
  margin: 16px 0 0;
  color: ${colors.grey24};
  font-size: 27px;
  line-height: 40px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.35px;
  text-align: center;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: 22px;
    line-height: 28px;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    margin: 16px 0 0;
    text-align: left;
    max-width: 385px;
    font-size: ${fontSizes.regular};
    line-height: 25px;
  }
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 690px;
  min-width: 595px;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    max-width: 565px;
    min-width: 422px;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    align-items: start;
    min-width: auto;
    width: 100%;
    max-width: 400px;
    padding: 0 8px;
  }
`

const HeroSection = ({ heroSectionRef }) => {
  const { t } = useTranslation('home')
  const displayTablet = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const router = useRouter()
  const isChinese = router.locale === 'zh'
  const searchRef = useRef(null)

  const scrollSearchToTop = () => {
    if (!displayMobile) return
    // Scroll search to top on mobile to allow more space for dropdown
    // Timeout is needed to delay scroll until virtual keyboard is open
    setTimeout(() => {
      window.scrollTo({
        top: searchRef.current.offsetTop - structure.header.heightMobile,
        left: 0
      })
    }, 150)
  }

  return (
    <Clip>
      <MainImage />
      <HeaderContainer ref={heroSectionRef}>
        <TextContainer>
          <HeroHeader isChinese={isChinese}>
            {t(`home:Canada's Safest Rental Platform`)}
          </HeroHeader>
          <HeroTagline>
            {t('home:Search verified listings from trusted landlords.')}
          </HeroTagline>
        </TextContainer>
        <SearchContainer ref={searchRef}>
          <CitySelector
            instanceId="homepage-select-city"
            id="homepage-select-city"
            showLarge={!displayTablet}
            onFocus={scrollSearchToTop}
          />
          <PopularCitiesWrapper>
            <PopularCities />
          </PopularCitiesWrapper>
        </SearchContainer>
      </HeaderContainer>
    </Clip>
  )
}

export default HeroSection
