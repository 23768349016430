import { breakpoints } from '@/styles'
import BackgroundImage from '@/components/background-image'

import homeMobile from './images/home-mobile.webp'
import homeTablet from './images/home-tablet.webp'
import homeWidscreen from './images/home-widscreen.webp'
import home from './images/home.webp'

const MainImage: React.FC<{}> = () => (
  <BackgroundImage
    name="home-page-hero"
    images={[homeWidscreen, home, homeTablet, homeMobile]}
    breakpoints={['1440px', breakpoints.tabletLandscape, breakpoints.phoneMax]}
    priority
  />
)

export default MainImage
